import React from 'react'
import Link from '../utilities/link'
import CommonFooter from '../components/footer/commonFooter'
import SiteNavigation from '../components/header/siteNavigation'
import { useTranslation } from 'react-i18next'

const Grazie = () => {
  const { t } = useTranslation()
  return (
  <>
    <SiteNavigation />
    <section className="hero is-medium">
      <div className="hero-body">
        <div className="content has-text-centered">
          <h1>{t('grazie.title')}</h1>
          <p>
            {t('grazie.subtitle')}
          </p>
          <div className="button is-light">
            <Link to={`/`} title="CTA Electronics Home Page">
              {t('grazie.callToAction')}
            </Link>
          </div>
        </div>
      </div>
    </section>
    <CommonFooter />
  </>
  )
}

export default Grazie
